import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { StaticImage } from 'gatsby-plugin-image';

import SEO from '../components/seo';

const Apply = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://paperform.co/__embed.min.js';
    document.body.appendChild(script);
  }, []);

  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "apply-background.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2560) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  const background = data.background.childImageSharp.fluid;

  return (
    <>
      <SEO title="Apply" />

      <BackgroundImage fluid={background}>
        <div className="h-screen relative w-screen">
          <div className="absolute bg-black bg-opacity-30 inset-0" />

          <div className="absolute top-1/2 transform -translate-y-1/2 w-full z-10">
            <div className="mb-3 mx-auto w-40">
              <StaticImage
                alt=""
                placeholder="blurred"
                src="../images/logo-white.png"
              />
            </div>

            <div data-paperform-id="fqrkewrl" />
          </div>
        </div>
      </BackgroundImage>
    </>
  );
};

export default Apply;
